<template>
  <filter-interest-block
    module-name="campaignPlanning"
    :use-label="false"
    :show-audience="false"
    :is-filter-applied="isFilterApplied"
    @submit="handleSubmit"
    @update="handleUpdate"
    @remove="handleRemove"
  />
</template>

<script>
const FilterInterestBlock = () => import(/* webpackChunkName: "filter-interest-campaign-planning" */ "@/blocks/common/filters/FilterInterest.vue")

export default {
  name: "FilterInterest",

  components: {
    FilterInterestBlock
  },

  computed: {
    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.$store.getters['campaignPlanning/findFilterByType']("interest"))
    },
  },

  methods: {
    handleSubmit(data) {
      this.$store.dispatch('campaignPlanning/addFilter', data)
    },

    handleUpdate(data) {
      this.$store.dispatch('campaignPlanning/updateFilter', data)
    },

    handleRemove(data) {
      this.$store.dispatch('campaignPlanning/removeFilter', data.id)
    }
  }
}
</script>
